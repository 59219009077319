.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*------------------custom css--------------------*/

html, body {
    height: 100%;
}

.content { position: relative; }

.form-wrapper .sv_custom_header {
    background-color: #fff!important;
    position: relative!important;
}

.form-wrapper {
    display: flex;
    padding:35px 25px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 0 50px rgba(0,0,0,0.1);
    margin: 1rem 1rem 2rem;
}

.form-wrapper .sv_main.sv_default_css {
    background-color: #fff;
}
.form-wrapper .sv_main>form {
    max-width: 500px;
    margin: 0 auto;
}

.form-wrapper .sv_main input[type="button"] {
    background-color: #05367B;
    border-radius: 5px;
    padding: 5px 5px;
    margin-top: 15px;
    margin-right: 0!important;
}
.form-wrapper .sv_main input[type="button"]:hover, 
.form-wrapper .sv_main input[type="button"]:active, 
.form-wrapper .sv_main input[type="button"]:focus  {
   background-color: #02c8ec;
   border-color: #02c8ec;
}
.form-wrapper .sv_main input[type="button"]:focus  {
   outline: none;
}
.form-wrapper .sv_q_text_root {
  height: 46px !important;
  border-radius: 5px;
}
.right-img-container {
  padding: 20px 20px 0 0; 
    position: absolute;
    right: 0;
    height: calc(100% - 63px);
    top: 62px;
    display: flex;
    flex-direction: column;
}
.animated-img.col-img {
    margin-top: auto;
}
.sv_page_title>span {
    color: #05367B;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 20px 0;
    display: block;
    text-align: center;
}
.form-wrapper .sv_main .sv_body {
    background-color: white;
    /*border-top: 2px solid #02c8ec;*/
    border-top: none;
}
.form-wrapper .sv_p_root .sv_row {
    background-color: #fff!important;
    border-bottom: none!important;
}
.form-wrapper .sv_body.sv_completed_page > h3 {
    font-size: 25px!important;
    font-weight: 700!important;
    color: #05367B!important;
}
.form-wrapper .sv_container .sv_row input:focus {
    border: 1px solid #02c8ec!important;
}
.sv_main .sv_select_wrapper:before {
    background-color: #02c8ec!important;
}
.sv_select_wrapper select:focus {
    border: 1px solid #02c8ec!important;
}
.navbar-brand img {
    max-width: 240px;
}

.body-wrapper{
  min-height: calc(100vh - 205px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.form-wrapper .sv_row .sv_q_erbox {
   background-color: transparent!important;
   padding: 0!important;
   border: none!important;
   margin: 0 !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0.5em 0 0 0 !important;
}


.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {
  height: 46px;
  border-radius: 5px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper:before {
  padding: 1.6em !important;
  border-radius: 0 5px 5px 0;
}
.logo-add { display: flex; align-items: center; margin: 25px 0; }
.logo-add p { border-left: #fff solid 1px; padding-left: 15px; margin-left: 15px; margin-bottom: 0; }

/*------------------screener page css--------------------*/

.survey-screener .sv_p_root .sv_qcbc {
    display: flex;
    flex-wrap: wrap;
}

.survey-screener .sv_p_root .sv_qcbc .sv_q_radiogroup {
    width: 50%;
}
.sv_q_checkbox_label .sv_q_checkbox_control_label {
    display: inline-block;
    width: calc(100% - 1.2em - 8px);
    vertical-align: top;
}

.sv_q_checkbox_label .sv_q_checkbox_control_item {
    display: inline-block;
}

.sv_q_radiogroup_label > span > span {
    display: inline-block!important;
    vertical-align: top;
    width: calc(100% - 1.75rem - 3px);
    font-size: 16px!important;
}

.sv_q_radiogroup_label .sv_q_radiogroup_control_item {
    display: inline-block;
}
.survey-screener .sv_q_title {
    display: flex;
}
.sv_main .sv_q_num {
    white-space: nowrap;
}

/*NDA page css*/

.nda-btn-wrapper {
    text-align: center;
}
.nda-heading {
    color: #05367B;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 20px 0;
    pointer-events: none;
   
}

.login-txt:hover {
    text-decoration: none;
    color: #fff;
}

.login-txt {
    background-color: #05367B;
    border-radius: 5px;
    padding: 5px;
    margin-right: 0!important;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
    border: none;
    min-width: 100px;
    cursor: pointer;
    max-width: 500px;
    display: inline-block;
    text-align: center;
    width: 100%;
}
.nda-btn-wrapper .login-txt:hover, .nda-btn-wrapper .login-txt:active,
.nda-btn-wrapper .login-txt:focus {
    background-color: #02c8ec;
    border-color: #02c8ec;
}
.animated-img.col-img {
    margin-top: auto;
    position: fixed;
    bottom: 0px;
    right: 35px;
}  

/*new changes*/

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
    font-size: 18px!important;
}
.form-wrapper .sv_main input[type="button"] {
    font-size: 18px;
}
.footer-wrapper img {
    max-width: 150px;
}


.social-icons {
  margin-top: 25;
  margin-bottom: 25;
  padding-top: 7;
  padding-bottom: 7;
  text-align: right;
}
.social-icons a { font-size: 2rem; }
@media (max-width: 767px) {
  .body-wrapper { flex-flow: wrap; min-height: calc(100vh - 109px); }
  .navbar-brand img { max-width: 100px; }
  .form-wrapper { margin-top: 8rem; }
  .logo-add { order: 1; }
  .social-icons { order: 2; }
  .social-icons a { font-size: 1.5rem; }
  .logo-text { order: 3; font-size: 12px; }
  .footer-wrapper img { width: 100px; }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-custom-1 {
  background-color: #85144b;
}

.bg-custom-2 {
background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}

.menuList {
  font-size: small;
  font-weight: 400;
  }

.headerQestionType {
  margin-top: 20px;
  margin-left: 15px;
  font-size: small;
  font-weight: 400;
  margin-bottom: 20px;
  }

  .backTextsize {
    font-size: small;
    font-weight: 400;
    }
    .bottom-video {
      max-width: 230px;
      height: 170px;
      bottom: 87px;
      left:20px;
      position: fixed;
  }
  .bottom-video .col-img div {
    width: 100% !important;
    height: 170px !important;
  }
  .logo-text {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.camraScreen {
  top: 102px;
  right: 20px;
  width: 300px;
  height: 150px;
  display: flex;
  position: fixed;
  background: none;
  align-items: center;
  justify-content: center;
}

.camraScreen video { max-width:100%; max-height: 100%; }
.videoScreen {
  width: 300px;
}


.sv_prev_btn {
display: none;
}

@media (max-width: 767px) {
  #root .navbar { position: fixed; width: 100%; z-index: 99; }
  .bottom-video {
    width: 40%;
    height: 100px;
    position: fixed;
    bottom: auto;
    top: 70px;
  }
  .bottom-video .col-img div {
    width: 100% !important;
    height: 100px !important;
  }
  .camraScreen {
    max-width: 40%;
    height: 100px;
    position: fixed;
    top: 70px;
    right: 20px;
  }
}
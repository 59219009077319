.home {
	min-height: calc(100vh - 161px);
	background-color: #fafafa;
	padding-top: 100px;
}
.item-blue { color: #05367B }
.item { text-align: center; }
.item svg { color: #05367B; }
.item p { margin-top: 50px; line-height: 1.3; }
.btn-primary {
	background-color: #05367B;
	border-color: #05367B;
}